@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --primary--dark: #171717;
}

body {
  background: #eff3f8;
  font-family: "Roboto", sans-serif;
}

/* utility  */

.button {
  padding: 0.4em 1.5em;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 7px;
  line-height: 25px;
  transition: background 0.1s ease-in-out;
  display: inline-block;
}

.button:hover {
  filter: drop-shadow(0px 6px 10px rgba(135, 135, 135, 0.25));
  background: #ffffff;
  border-radius: 7px;
  color: black;
}

button:disabled {
  background: gray;
  color: white;
  cursor: not-allowed;
}

.button__dark {
  background: #171717;
  color: #ffffff;
}

.button__red {
  background: #f64d4d;
  color: #ffffff;
}
.button__blue {
  background: #4da5f6;
  color: #ffffff;
}

.button__yellow {
  background: #f1c240;
  color: #000;
}

/* Layout  */
.main_left {
  min-height: 100vh;
  background: var(--primary--dark);
  font-size: 16px;
  position: fixed;
  padding: 0;
}

.main_right {
  flex: 1;
}

/* sidebar  */
.sidebar {
  width: 100%;
  padding: 2em 1em;
}

.sidebar h4 {
  margin-top: 2em;
  margin-bottom: 1em;
  color: #868686;
}

.sidebar h3 {
  margin-top: 1em;
  color: #868686;
}
.sidebar ul {
  list-style: none;
  width: 100%;
}

.sidebar ul a {
  color: #c1c1c1;
  width: 100%;
  padding: 15px;
  margin: 1em 0;
  border-radius: 6px;
  display: block;
  text-decoration: none;
  transition: background-color 0.2s;
}

.sidebar ul a:hover {
  background-color: #292d32;
}

.sidebar ul a.selected {
  background-color: #292d32;
}

.btn-form {
  margin-top: 15px;
}

.cursor-pointer {
  cursor: pointer;
}

.warehouse-number {
  display: none;
}

/* page header  */
.page__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding: 1.5em 2em;
  box-shadow: 0px 4px 14px rgba(223, 223, 223, 0.25);
}

.page__header i {
  color: #ff6115;
}

.page__body {
  padding: 1.5em 2em;
}

/* shadow box  */

.shadow__box {
  background: #ffffff;
  box-shadow: 0px 0px 40px rgba(216, 216, 216, 0.25);
  border-radius: 15px;
  padding: 1.5em 2em;
  margin-bottom: 1em;
}

.custom__form_input {
  background: #eeeeee;
  border-radius: 7px;
  color: #5a5a5a;
  padding: 0.5em 1em;
  border: none;
  outline-color: #cacaca;
  height: 40px;
  /* max-width: 350px; */
}

/* Upload Inventory File  */
#inventoryFile {
  display: none;
}

.FileLabel {
  width: 100%;
  padding: 3em;
  border: 3px dashed #4da5f6;
  text-align: center;
  font-size: 25px;
  cursor: pointer;
  color: #9e9e9e;
  text-transform: capitalize;
}

.listing-form {
  margin-left: 10px;
  margin-right: 10px;
}

.text-area {
  resize: none;
}